.amplify-tabs {
  display: none !important;
}

div[data-amplify-container] {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.amplify-button--primary {
  background-color: #42A6D9;
  border-radius: 5px;
  color: #FFFFFF;
}

.amplify-button--primary:hover {
  background-color: #016EA5;
  border-radius: 5px;
  color: #FFFFFF;
}